<script setup lang="ts">
const props = defineProps<{
    url?: string | null;
    size?: 'sm' | 'md' | 'lg';
    alt?: string;
}>();

const sizeClasses = {
    xs: 'size-6',
    sm: 'size-8',
    md: 'size-14',
    lg: 'size-48',
};

const avatarSize = computed(() => {
    return sizeClasses[props.size || 'md'];
});

const showDefaultAvatar = computed(() => !props.url);
</script>

<template>
    <div class="relative">
        <!-- Default SVG Avatar -->
        <svg
            v-if="showDefaultAvatar"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            :class="[
                avatarSize,
                'rounded-full bg-gray-400',
                // Add shadow and anti-aliasing for SVG
                'shadow-sm',
            ]"
        >
            <path
                fill-rule="evenodd"
                d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                clip-rule="evenodd"
            />
        </svg>

        <!-- User Avatar Image -->
        <div
            v-else
            :class="[
                avatarSize,
                'overflow-hidden rounded-full',
                'ring-1 ring-white',
                'shadow-sm',
                'border border-white/10',
            ]"
        >
            <img
                :src="url"
                :alt="alt || 'User avatar'"
                :class="[
                    'h-full w-full',
                    'object-cover object-center',
                    'antialiased',
                    'filter-none',
                ]"
                @error="showDefaultAvatar = true"
            />
        </div>
    </div>
</template>
